import { useState } from "react";
import { useTranslation } from "react-i18next";

import OptionSelectModal from "../../OptionSelectModal";

import { Icon } from "~/components/atoms/Icons";
import SingleSelect, {
  SelectOptions,
} from "~/components/atoms/SelectBox/SingleSelect";
import Typo from "~/components/atoms/Typo";
import { cn } from "~/utilities/cn";
import { OPTION_ANY } from "~/utilities/constants/common";
import { ETypoColor } from "~/utilities/enums/Colors";
import { EIcon } from "~/utilities/enums/Icons";
import { ETypoVariant } from "~/utilities/enums/Typo";

export enum EDrawerSingleSelectVariant {
  DEFAULT = "DEFAULT",
  MODAL = "MODAL",
}
export interface IDrawerSingleSelectProps {
  options?: SelectOptions[];
  value: string;
  placeholder?: string;
  variant?: EDrawerSingleSelectVariant;
  name?: string;
  showSelectModal?: boolean;
  onSelect: (value: string) => void;
}

const DrawerSingleSelect: React.FC<IDrawerSingleSelectProps> = ({
  options = [],
  value,
  placeholder = "",
  name = "",
  onSelect,
  showSelectModal = false,
  variant = EDrawerSingleSelectVariant.DEFAULT,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  if (variant === EDrawerSingleSelectVariant.MODAL) {
    return (
      <>
        <SingleSelect
          placeholder={placeholder}
          data={options}
          name={name}
          title=""
          defaultValue={[OPTION_ANY, ...(options || [])]?.find(
            (l) => l.id === value
          )}
          variant="fill"
          containerClass="w-full flex-1 h-0"
          textSelectedClass="text-sub-title-14 text-color"
          listboxButtonClass="py-2 border border-color"
          placeholderProps={{
            variant: ETypoVariant.BODY_TITLE_16,
            className: "lg:!text-sub-title-14 !text-color",
          }}
          showOptionSelectModal
          onShowOptionSelectModal={() => setOpen(true)}
        />
        {showSelectModal && (
          <OptionSelectModal
            title={placeholder}
            open={open}
            onClose={() => setOpen(false)}
            options={options}
            value={value}
            onChange={(value) => {
              onSelect(value);
              setOpen(false);
            }}
          />
        )}
      </>
    );
  }
  return (
    <div className="flex flex-col">
      {options?.map(({ id, name }) => {
        const isActive = value === id;
        return (
          <button
            key={`DrawerSingleSelect-${id}`}
            className={cn(
              "flex cursor-pointer flex-row flex-nowrap justify-between py-3"
            )}
            onClick={() => onSelect(String(id))}
          >
            <Typo
              tag="p"
              variant={ETypoVariant.SUB_TITLE_16}
              color={isActive ? ETypoColor.SUCCESS : ETypoColor.TEXT}
            >
              {name}
            </Typo>
            {isActive && (
              <Icon TypeIcon={EIcon.CheckedIcon} width={16} height={16} />
            )}
          </button>
        );
      })}
    </div>
  );
};

export default DrawerSingleSelect;
