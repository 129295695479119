import { useState } from "react";

import OptionSelectModal from "../../OptionSelectModal";

import { Icon } from "~/components/atoms/Icons";
import { InputProps } from "~/components/atoms/Input/type";
import { SelectOptions } from "~/components/atoms/SelectBox/SingleSelect";
import { cn } from "~/utilities/cn";
import { EIcon } from "~/utilities/enums/Icons";

type Props = {
  inputProps: InputProps;
  containerClass?: string;
  options: SelectOptions[];
  iconSize?: {
    width: number;
    height: number;
  };
  title?: string;
  value: string;
  onChange: (value: string) => void;
};
function DrawerSearchInput({
  inputProps,
  iconSize,
  containerClass = "",
  options,
  title = "",
  value,
  onChange,
}: Props) {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <div className="relative">
      <label
        className={cn(
          "flex w-full items-center gap-[10px] rounded-[28px] border border-color bg-searchBarBackground px-4 py-2 text-searchBarText text-opacity-50",
          containerClass
        )}
      >
        <input
          type="text"
          {...inputProps}
          className={cn(
            "grow border-none bg-transparent text-sub-title-16 text-searchBarText outline-none",
            inputProps?.className || ""
          )}
          value={options?.find((option) => option?.id === value)?.name || ""}
          onChange={() => {}}
        />
        <figure className="cursor-pointer transition-all duration-500 hover:scale-110">
          <Icon
            TypeIcon={EIcon.SearchIcon}
            width={20}
            height={20}
            {...iconSize}
            className="text-color"
          />
        </figure>
      </label>
      <OptionSelectModal
        title={title}
        open={open}
        onClose={() => setOpen(false)}
        options={options}
        value={value}
        onChange={(value) => {
          onChange(value);
          setOpen(false);
        }}
      />
      <div
        className="absolute left-0 top-0 h-full w-full cursor-pointer bg-transparent"
        onClick={() => setOpen(true)}
      />
    </div>
  );
}

export default DrawerSearchInput;
