import {
  useLocation,
  useNavigate,
  useRouteLoaderData,
  useSearchParams,
} from "@remix-run/react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useRemixForm } from "remix-hook-form";

import FilterBarDrawerContent, {
  EFilterBarDrawerContentVariant,
} from "./FilterBarDrawerContent";
import SearchBar from "../SearchBar";
import { LocationOptionTemplate } from "../SearchBar/OptionTemplates";

import Button from "~/components/atoms/Button";
import { CustomDrawer } from "~/components/atoms/CustomDrawer";
import { CustomDrawerRef } from "~/components/atoms/CustomDrawer/CustomDrawer.stories";
import { Icon } from "~/components/atoms/Icons";
import { InputRange } from "~/components/atoms/InputRange";
import { SingleSelect } from "~/components/atoms/SelectBox";
import Typo from "~/components/atoms/Typo";
import { Form } from "~/components/templates/form";
import { IRouteLoaderData } from "~/types/routeLoaderData";
import { cn } from "~/utilities/cn";
import { OPTION_ANY, OPTIONS_TENURE } from "~/utilities/constants/common";
import { ETypoColor } from "~/utilities/enums/Colors";
import { EIcon } from "~/utilities/enums/Icons";
import { Slug } from "~/utilities/enums/Slug";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";
import { convertUnitTypeOptions } from "~/utilities/helpers/unitType";
import {
  filterBarSchema,
  TFilterBarFormData,
} from "~/utilities/schema/filter-bar";

export enum EFilterBarMobileVariant {
  DEFAULT = "default",
  DRAWER = "drawer",
}

interface IFilterBarMobileProps {
  variant?: EFilterBarMobileVariant;
}

const FilterBarMobile: React.FC<IFilterBarMobileProps> = ({
  variant = EFilterBarMobileVariant.DEFAULT,
}) => {
  const { t } = useTranslation();
  const handleFormInit = async (
    _methods: ReturnType<typeof useRemixForm<TFilterBarFormData>>
  ) => {};
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const { filterOptions } = useRouteLoaderData("root") as IRouteLoaderData;
  const drawerRef = useRef<CustomDrawerRef>(null);

  const unitTypeOptions = filterOptions.unitTypes;
  const locationOptions = filterOptions.locations;
  const topOptions = filterOptions.tops;
  const priceOptions = filterOptions?.prices;

  const defaultValues: Partial<TFilterBarFormData> = {
    s: searchParams.get("s") || "",
    type: searchParams.get("type") || "",
    tenure: searchParams.get("tenure") || "",
    rangeMin: searchParams.get("rangeMin") || "",
    rangeMax: searchParams.get("rangeMax") || "",
    top: searchParams.get("top") || "",
  };

  const navigate = useNavigate();
  return (
    <Form<TFilterBarFormData>
      validationSchema={filterBarSchema}
      onMethodInit={handleFormInit}
      useFormProps={{
        mode: "onChange",
        defaultValues,
      }}
      className="filter-bar-form"
      action={Slug.PROJECTS + location.search}
    >
      {({ register, watch, setValue, reset, formState: { errors } }) => (
        <>
          {variant === EFilterBarMobileVariant.DEFAULT && (
            <div className="flex flex-col gap-3">
              <SearchBar
                containerClass="lg:py-2 lg:!text-sub-title-14"
                iconSize={{
                  width: 18,
                  height: 18,
                }}
                inputProps={{
                  className: "!placeholder-filterBarMobilePlaceholder flex-1",
                  placeholder: t("search_project_placeholder"),
                  ...register("s"),
                  value: watch("s"),
                }}
                enableAutocomplete
                asyncAutocomplete
                onSelectedChange={(option) => {
                  navigate(`/projects/${option?.projectSlug}`);
                }}
                renderOptionTemplate={({ option, searchString }) => (
                  <LocationOptionTemplate
                    searchString={searchString}
                    option={option}
                  />
                )}
              />
              <div className="flex gap-x-7 rounded-3xl bg-filterBarMobileBackgroundColor lg:flex-col lg:rounded-xl">
                <div className="grid flex-1 grid-cols-6 gap-x-2 gap-y-2">
                  <div className="lg:col-span-3">
                    <SingleSelect
                      placeholder={t("location")}
                      error={errors.location?.message}
                      defaultValue={[
                        OPTION_ANY,
                        ...(locationOptions || []),
                      ]?.find(
                        (l) => l.id === searchParams.get("location") || ""
                      )}
                      data={locationOptions}
                      name="location"
                      title=""
                      dropdownClass="w-[calc(var(--button-width)+85px)]"
                      variant="fill"
                      containerClass="w-full flex-1"
                      textSelectedClass="lg:!text-sub-title-14 text-filterBarText"
                      listboxButtonClass="py-2"
                      placeholderProps={{
                        variant: ETypoVariant.BODY_TITLE_16,
                        className:
                          "lg:!text-sub-title-14 !text-filterBarMobilePlaceholder",
                      }}
                    />
                  </div>
                  <div className="lg:col-span-3">
                    <SingleSelect
                      placeholder={t("type")}
                      error={errors.type?.message}
                      data={convertUnitTypeOptions(unitTypeOptions)}
                      name="type"
                      title=""
                      defaultValue={[
                        OPTION_ANY,
                        ...convertUnitTypeOptions(unitTypeOptions || []),
                      ]?.find((l) => l.id === searchParams.get("type") || "")}
                      variant="fill"
                      containerClass="w-full flex-1"
                      textSelectedClass="lg:!text-sub-title-14 text-filterBarText"
                      listboxButtonClass="py-2"
                      placeholderProps={{
                        variant: ETypoVariant.BODY_TITLE_16,
                        className:
                          "lg:!text-sub-title-14 !text-filterBarMobilePlaceholder",
                      }}
                    />
                  </div>
                  <div className="lg:col-span-3">
                    <SingleSelect
                      placeholder={t("tenure")}
                      error={errors.tenure?.message}
                      data={OPTIONS_TENURE}
                      title=""
                      name="tenure"
                      defaultValue={[OPTION_ANY, ...OPTIONS_TENURE]?.find(
                        (l) => l.id === searchParams.get("tenure") || ""
                      )}
                      variant="fill"
                      containerClass="w-full flex-1"
                      textSelectedClass="lg:!text-sub-title-14 text-filterBarText"
                      listboxButtonClass="py-2"
                      placeholderProps={{
                        variant: ETypoVariant.BODY_TITLE_16,
                        className:
                          "lg:!text-sub-title-14 !text-filterBarMobilePlaceholder",
                      }}
                    />
                  </div>
                  <div className="lg:col-span-3">
                    <SingleSelect
                      placeholder={t("T.O.P")}
                      error={errors.top?.message}
                      data={topOptions}
                      title=""
                      name="top"
                      defaultValue={[OPTION_ANY, ...(topOptions || [])]?.find(
                        (l) => l.id === searchParams.get("top") || ""
                      )}
                      variant="fill"
                      containerClass="w-full flex-1"
                      textSelectedClass="lg:!text-sub-title-14 text-filterBarText"
                      listboxButtonClass="py-2"
                      placeholderProps={{
                        variant: ETypoVariant.BODY_TITLE_16,
                        className:
                          "lg:!text-sub-title-14 !text-filterBarMobilePlaceholder",
                      }}
                    />
                  </div>
                  <div className="col-span-2 lg:col-span-6">
                    <InputRange
                      errors={{
                        maxErrors: errors.rangeMax?.message,
                        minErrors: errors.rangeMin?.message,
                      }}
                      minValueSelect={{
                        placeholder: t("min"),
                        error: errors.rangeMin?.message,
                        data: priceOptions,
                        title: "",
                        name: "rangeMin",
                        defaultValue: [
                          OPTION_ANY,
                          ...(priceOptions || []),
                        ]?.find(
                          (l) => l.id === searchParams.get("rangeMin") || ""
                        ),
                        variant: "fill",
                        containerClass: "w-full flex-1",
                        textSelectedClass:
                          "lg:!text-sub-title-14 text-filterBarText",
                        placeholderProps: {
                          variant: ETypoVariant.BODY_TITLE_16,
                          className:
                            "lg:!text-sub-title-14 !text-filterBarMobilePlaceholder",
                        },
                        listboxButtonClass: "py-2",
                      }}
                      maxValueSelect={{
                        placeholder: t("max"),
                        error: errors.rangeMax?.message,
                        data: priceOptions,
                        title: "",
                        name: "rangeMax",
                        defaultValue: [
                          OPTION_ANY,
                          ...(priceOptions || []),
                        ]?.find(
                          (l) => l.id === searchParams.get("rangeMax") || ""
                        ),
                        variant: "fill",
                        containerClass: "w-full flex-1",
                        textSelectedClass:
                          "lg:!text-sub-title-14 text-filterBarText",
                        placeholderProps: {
                          variant: ETypoVariant.BODY_TITLE_16,
                          className:
                            "lg:!text-sub-title-14 !text-filterBarMobilePlaceholder",
                        },
                        listboxButtonClass: "py-2",
                      }}
                      title={{
                        text: "",
                        fontSize: ETypoVariant.SUB_TITLE_20,
                        className: "mb-0",
                      }}
                      variant="select"
                    />
                  </div>
                </div>
                <div className="flex justify-end self-end pb-1 xxl:justify-center xxl:pt-4.5 lg:w-full lg:pb-0">
                  <Button
                    type="submit"
                    className="border-none bg-buttonSearchBackground text-buttonSearchText lg:w-full lg:px-14 lg:py-2"
                  >
                    <Typo
                      className="whitespace-nowrap font-normal capitalize"
                      tag={ETypoTag.SPAN}
                      variant={ETypoVariant.BODY_TITLE_16}
                      color={ETypoColor.BUTTON_SEARCH}
                    >
                      {t("search")}
                    </Typo>
                  </Button>
                </div>
              </div>
            </div>
          )}
          {variant === EFilterBarMobileVariant.DRAWER && (
            <div
              data-name="FilterBar-sticky"
              className={cn("bg-filterBarMobileButtonBackground")}
            >
              <div className="container flex flex-col gap-x-6 py-4 lg:px-0">
                <div className="flex w-full items-end justify-between gap-8 lg:flex-col lg:gap-3 [&>*:nth-child(even)]:flex-1 ">
                  <div className="lg:w-full lg:flex-1 lg:px-6">
                    <SearchBar
                      inputProps={{
                        className: cn(
                          "w-full outline-none border-none text-searchBarText placeholder:text-searchBarPlaceholder text-sub-title-14 pt-0 px-0 mb-0 bg-searchBarBackground"
                        ),
                        placeholder: t("search_project_placeholder"),
                        ...register("s"),
                        value: watch("s"),
                      }}
                      enableAutocomplete
                      asyncAutocomplete
                      onSelectedChange={(option) => {
                        navigate(`/projects/${option?.projectSlug}`);
                      }}
                      renderOptionTemplate={({ option, searchString }) => (
                        <LocationOptionTemplate
                          searchString={searchString}
                          option={option}
                        />
                      )}
                      containerClass={cn(
                        "p-0 border-b border-filterBarMobileButtonBackground rounded-none pb-[8px] lg:px-0 items-start",
                        "lg:bg-white  lg:border-none lg:px-4 lg:py-3 lg:rounded-[28px]"
                      )}
                    />
                  </div>
                  <CustomDrawer
                    ref={drawerRef}
                    wrapperTitleClass="w-full gap-6"
                    title={
                      <div className="flex flex-1 items-center justify-between">
                        <Typo
                          className="whitespace-nowrap font-bold capitalize"
                          tag={ETypoTag.P}
                          variant={ETypoVariant.HEADER_20}
                          color={ETypoColor.TEXT}
                        >
                          {t("filter")}
                        </Typo>
                        <button
                          className="cursor-pointer text-body-title-12 text-error"
                          onClick={() => {
                            reset({
                              location: "",
                              type: "",
                              tenure: "",
                              rangeMin: 0,
                              rangeMax: 0,
                            });
                          }}
                        >
                          {t("clear_all")}
                        </button>
                      </div>
                    }
                    side={
                      <FilterBarDrawerContent
                        variant={EFilterBarDrawerContentVariant.ACCORDION}
                        tabOptions={{
                          location: locationOptions,
                          type: unitTypeOptions,
                          tenure: OPTIONS_TENURE,
                          top: topOptions,
                          rangeMin: priceOptions,
                          rangeMax: priceOptions,
                        }}
                        defaultValue={defaultValues}
                        onFilter={(values) => {
                          setValue(
                            "location",
                            (values?.location as string) || ""
                          );
                          setValue("type", (values?.type as string) || "");
                          setValue("top", (values?.top as string) || "");
                          setValue("tenure", (values?.tenure as string) || "");
                          setValue("rangeMin", Number(values?.rangeMin) || "");
                          setValue("rangeMax", Number(values?.rangeMax) || "");
                        }}
                      />
                    }
                    actions={
                      <div className="flex w-full items-center justify-center pb-0">
                        <Button
                          type="submit"
                          className="lg:px-13"
                          onClick={() => {
                            setTimeout(() => {
                              drawerRef.current?.onClose();
                            }, 500);
                          }}
                        >
                          <Typo
                            className="whitespace-nowrap font-normal capitalize"
                            tag={ETypoTag.SPAN}
                            variant={ETypoVariant.BODY_TITLE_16}
                            color={ETypoColor.WHITE}
                          >
                            {t("apply_filter")}
                          </Typo>
                        </Button>
                      </div>
                    }
                  >
                    <div
                      className={cn(
                        "filter-bar-sub-container",
                        "lg:no-scrollbar lg:bg-Color flex flex-1 flex-row flex-nowrap items-center gap-3 text-filterBarMobileButtonText lg:max-w-full lg:overflow-auto lg:px-6"
                      )}
                    >
                      <div className="flex justify-start gap-[10px] rounded-[12.5px] border border-filterBarMobileButtonText px-2 py-1">
                        <Icon
                          TypeIcon={EIcon.MobileFilterIcon}
                          height={13}
                          width={12}
                        />
                        <Typo
                          tag="p"
                          color="text-filterBarMobileButtonText"
                          variant="text-body-title-14"
                          className="font-bold capitalize"
                        >
                          {t("filter")}
                        </Typo>
                      </div>
                      <div className="flex h-full basis-[15%] flex-col gap-3">
                        <Typo
                          tag="p"
                          color="text-filterBarMobileText"
                          variant="text-body-title-14"
                          className="font-bold capitalize lg:hidden"
                        >
                          {t("location")}
                        </Typo>
                        <SingleSelect
                          placeholder={t("location")}
                          error={errors.location?.message}
                          defaultValue={locationOptions?.find(
                            (l) => l.id === searchParams.get("location") || ""
                          )}
                          data={locationOptions}
                          name="location"
                          dropdownClass="w-[calc(var(--button-width)+85px)] lg:hidden"
                          containerClass="flex-1 pb-0"
                          listboxButtonContainerClass="border-transparent lg:pb-0 items-start"
                          listboxButtonClass="pb-0 h-full block"
                          placeholderClass="leading-[1.2] opacity-1  line-clamp-1 !text-filterBarMobileButtonText font-bold"
                          dropdownIconClassName="ml-2 text-filterBarMobileText"
                          placeholderProps={{
                            variant: ETypoVariant.BODY_TITLE_14,
                          }}
                          textSelectedClass="text-body-title-14"
                          placeholderContainerClass="items-center h-full justify-start"
                        />
                      </div>
                      <div className="flex h-full basis-[15%] flex-col gap-3">
                        <Typo
                          tag="p"
                          color="text-filterBarMobileText"
                          variant="text-body-title-14"
                          className="font-bold capitalize lg:hidden"
                        >
                          {t("price")}
                        </Typo>
                        <SingleSelect
                          placeholder={t("price")}
                          error={errors.location?.message}
                          defaultValue={locationOptions?.find(
                            (l) => l.id === searchParams.get("location") || ""
                          )}
                          data={locationOptions}
                          name="rangeMin"
                          dropdownClass="w-[calc(var(--button-width)+85px)] lg:hidden"
                          containerClass="flex-1"
                          listboxButtonContainerClass="border-transparent lg:pb-0 items-start"
                          listboxButtonClass="pb-0 h-full block"
                          placeholderClass="leading-[1.2] opacity-1  line-clamp-1 !text-filterBarMobileButtonText font-bold"
                          dropdownIconClassName="ml-2 text-filterBarMobileText"
                          placeholderProps={{
                            variant: ETypoVariant.BODY_TITLE_14,
                          }}
                          textSelectedClass="text-body-title-14"
                          placeholderContainerClass="items-center h-full justify-start"
                        />
                      </div>

                      <div className="flex h-full basis-[15%] flex-col gap-3">
                        <Typo
                          tag="p"
                          color="text-filterBarMobileText"
                          variant="text-body-title-14"
                          className="font-bold capitalize lg:hidden"
                        >
                          {t("TOP")}
                        </Typo>
                        <SingleSelect
                          placeholder={t("TOP")}
                          error={errors.type?.message}
                          name="top"
                          title=""
                          data={topOptions}
                          dropdownClass="lg:hidden"
                          defaultValue={topOptions?.find(
                            (l) => l.id === searchParams.get("top") || ""
                          )}
                          containerClass="flex-1"
                          listboxButtonContainerClass="border-transparent lg:pb-0 items-start"
                          listboxButtonClass="pb-0 h-full block"
                          placeholderClass="leading-[1.2] opacity-1 !text-filterBarMobileButtonText capitalize font-bold"
                          dropdownIconClassName="ml-2 text-filterBarMobileText"
                          placeholderProps={{
                            variant: ETypoVariant.BODY_TITLE_14,
                          }}
                          textSelectedClass="text-body-title-14"
                          placeholderContainerClass="items-center h-full justify-start"
                        />
                      </div>

                      <div className="flex h-full basis-[15%] flex-col gap-3">
                        <Typo
                          tag="p"
                          color="text-filterBarMobileText"
                          variant="text-body-title-14"
                          className="font-bold capitalize lg:hidden"
                        >
                          {t("tenure")}
                        </Typo>
                        <SingleSelect
                          placeholder={t("tenure")}
                          error={errors.type?.message}
                          name="tenure"
                          title=""
                          data={OPTIONS_TENURE}
                          dropdownClass="lg:hidden"
                          defaultValue={OPTIONS_TENURE?.find(
                            (l) => l.id === searchParams.get("type") || ""
                          )}
                          containerClass="flex-1"
                          listboxButtonContainerClass="border-transparent lg:pb-0 items-start"
                          listboxButtonClass="pb-0 h-full block"
                          placeholderClass="leading-[1.2] opacity-1 !text-filterBarMobileButtonText capitalize font-bold"
                          dropdownIconClassName="ml-2 text-filterBarMobileText"
                          placeholderProps={{
                            variant: ETypoVariant.BODY_TITLE_14,
                          }}
                          textSelectedClass="text-body-title-14"
                          placeholderContainerClass="items-center h-full justify-start"
                        />
                      </div>
                      <div className="flex h-full basis-[15%] flex-col gap-3 lg:min-w-[70px] lg:max-w-[70px]">
                        <Typo
                          tag="p"
                          color="text-filterBarMobileText"
                          variant="text-body-title-14"
                          className="font-bold capitalize lg:hidden"
                        >
                          {t("type")}
                        </Typo>
                        <SingleSelect
                          placeholder={t("type")}
                          error={errors.type?.message}
                          name="type"
                          title=""
                          data={convertUnitTypeOptions(unitTypeOptions)}
                          dropdownClass="lg:hidden"
                          defaultValue={convertUnitTypeOptions(
                            unitTypeOptions
                          )?.find(
                            (l) => l.id === searchParams.get("type") || ""
                          )}
                          containerClass="flex-1"
                          listboxButtonContainerClass="border-transparent lg:pb-0 items-start"
                          listboxButtonClass="pb-0 h-full block"
                          placeholderClass="leading-[1.2] opacity-1 !text-filterBarMobileButtonText capitalize font-bold"
                          dropdownIconClassName="ml-2 text-filterBarMobileText"
                          placeholderProps={{
                            variant: ETypoVariant.BODY_TITLE_14,
                          }}
                          textSelectedClass="text-body-title-14"
                          placeholderContainerClass="items-center h-full justify-start"
                        />
                      </div>
                    </div>
                  </CustomDrawer>
                </div>
                <div className="flex justify-end self-start border-none xxl:justify-center xxl:pt-4.5 lg:hidden lg:w-full lg:pb-0">
                  <Button
                    type="submit"
                    className="border-none bg-filterBarMobileButtonBackground hover:border-filterBarMobileButtonBackground hover:bg-filterBarMobileButtonBackground active:hover:bg-filterBarMobileButtonBackground lg:px-13"
                  >
                    <Typo
                      className="whitespace-nowrap font-normal capitalize"
                      tag={ETypoTag.SPAN}
                      variant={ETypoVariant.BODY_TITLE_16}
                      color={ETypoColor.FILTER_BAR_MOBILE_BUTTON_TEXT}
                    >
                      {t("search")}
                    </Typo>
                  </Button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </Form>
  );
};

export default FilterBarMobile;
