import { useEffect, useState } from "react";
import { Accordion, Tabs } from "react-daisyui";
import { useTranslation } from "react-i18next";

import DrawerRange from "../../Drawer/DrawerRange/DrawerRange";
import DrawerSingleSelect, {
  EDrawerSingleSelectVariant,
} from "../../Drawer/DrawerSingleSelect/DrawerSingleSelect";
import DrawerSearchInput from "../../Drawer/DrawSearchInput/DrawerSearchInput";
import OptionSelectModal from "../../OptionSelectModal";

import { SelectOptions } from "~/components/atoms/SelectBox/SingleSelect";
import Typo from "~/components/atoms/Typo";
import { cn } from "~/utilities/cn";
import { ETypoColor } from "~/utilities/enums/Colors";
import { ETypoVariant } from "~/utilities/enums/Typo";
import { convertUnitTypeOptions } from "~/utilities/helpers/unitType";
import { TFilterBarFormData } from "~/utilities/schema/filter-bar";

export enum EFilterBarDrawerContentVariant {
  DEFAULT = "DEFAULT",
  ACCORDION = "ACCORDION",
}
export interface IFilterBarDrawerContentProps {
  tabOptions: Partial<Record<keyof TFilterBarFormData, SelectOptions[]>>;
  defaultValue?: Partial<TFilterBarFormData>;
  variant?: EFilterBarDrawerContentVariant;
  onFilter: (value?: Record<string, unknown>) => void;
}

const FilterBarDrawerContent: React.FC<IFilterBarDrawerContentProps> = ({
  tabOptions,
  defaultValue,
  variant = EFilterBarDrawerContentVariant.DEFAULT,
  onFilter,
}) => {
  const [localValues, setLocalValues] = useState<
    Partial<TFilterBarFormData> | undefined
  >(defaultValue);

  const [openSelectModal, setOpenSelectModal] = useState<{
    open: boolean;
    field: "top" | "tenure" | "type" | "location" | "rangeMin" | "rangeMax";
    options: SelectOptions[];
    title: string;
  }>({
    open: false,
    field: "top",
    options: [],
    title: "",
  });

  const { t } = useTranslation();

  const onChangeLocalValues = ({
    type,
    value,
  }: {
    type: Partial<keyof TFilterBarFormData>;
    value: string;
  }) => {
    setLocalValues((pre) =>
      pre ? { ...pre, [type]: value } : { [type]: value }
    );
  };

  useEffect(() => {
    onFilter(localValues);
  }, [JSON.stringify(localValues)]);

  const totalTabs: {
    value: "top" | "tenure" | "type" | "location" | "rangeMin" | "rangeMax";
    label: string;
    options?: SelectOptions[];
    component: React.ReactNode;
    showAccordionComponent: boolean;
    accordionComponent?: React.ReactNode;
  }[] = [
    {
      value: "location",
      label: "Location",
      options: tabOptions?.location || [],
      showAccordionComponent: true,
      component: (
        <DrawerSingleSelect
          options={tabOptions?.location}
          value={(localValues?.location as string) || ""}
          onSelect={(value) => {
            onChangeLocalValues({ type: "location", value });
          }}
        />
      ),
      accordionComponent: (
        <DrawerSearchInput
          inputProps={{
            placeholder: t("search"),
          }}
          options={tabOptions?.location || []}
          title={t("location")}
          value={(localValues?.location as string) || ""}
          onChange={(value) => {
            onChangeLocalValues({ type: "location", value });
          }}
        />
      ),
    },
    {
      value: "rangeMin",
      label: "Price Range",
      showAccordionComponent: true,
      component: (
        <DrawerRange
          fromValue={String(localValues?.rangeMin) || "0"}
          toValue={String(localValues?.rangeMax) || "0"}
          fromName="rangeMin"
          toName="rangeMax"
          onChangeValue={(value) => {
            onChangeLocalValues({ type: "rangeMin", value: value.from });
            onChangeLocalValues({ type: "rangeMax", value: value.to });
          }}
        />
      ),
      accordionComponent: (
        <DrawerRange
          fromValue={String(localValues?.rangeMin) || "0"}
          toValue={String(localValues?.rangeMax) || "0"}
          fromTitle={t("no_min")}
          toTitle={t("no_max")}
          containerClassname="flex-row"
          fromWrapperClass="w-1/2"
          toWrapperClass="w-1/2"
          toTitleClass="!font-normal min-w-fit"
          fromTitleClass="!font-normal min-w-fit"
          fromInputClass="bg-transparent !text-color text-body-title-14"
          toInputClass="bg-transparent !text-color text-body-title-14 "
          wrapFromInputClass="pb-1"
          wrapToInputClass="pb-1"
          variant="select_modal"
          fromOptions={tabOptions?.rangeMin}
          toOptions={tabOptions?.rangeMax}
          fromName="rangeMin"
          toName="rangeMax"
          onChangeValue={(value) => {
            onChangeLocalValues({ type: "rangeMin", value: value.from });
            onChangeLocalValues({ type: "rangeMax", value: value.to });
          }}
        />
      ),
    },
    {
      value: "top",
      label: "T.O.P",
      options: tabOptions?.top || [],
      showAccordionComponent: false,
      component: (
        <DrawerSingleSelect
          name="top"
          options={tabOptions.top}
          value={(localValues?.top as string) || ""}
          onSelect={(value) => {
            onChangeLocalValues({ type: "top", value });
          }}
        />
      ),
      accordionComponent: (
        <DrawerSingleSelect
          options={tabOptions.top}
          name="top"
          value={(localValues?.top as string) || ""}
          placeholder={t("T.O.P")}
          onSelect={(value) => {
            onChangeLocalValues({ type: "top", value });
          }}
          variant={EDrawerSingleSelectVariant.MODAL}
        />
      ),
    },
    {
      value: "type",
      label: "Type",
      options: convertUnitTypeOptions(tabOptions?.type || []),
      showAccordionComponent: false,
      component: (
        <DrawerSingleSelect
          options={convertUnitTypeOptions(tabOptions?.type || [])}
          value={(localValues?.type as string) || ""}
          onSelect={(value) => {
            onChangeLocalValues({ type: "type", value });
          }}
        />
      ),
      accordionComponent: (
        <DrawerSingleSelect
          options={convertUnitTypeOptions(tabOptions?.type || [])}
          placeholder={t("type")}
          value={(localValues?.type as string) || ""}
          onSelect={(value) => {
            onChangeLocalValues({ type: "type", value });
          }}
          variant={EDrawerSingleSelectVariant.MODAL}
        />
      ),
    },
    {
      value: "tenure",
      label: "Tenure",
      options: tabOptions?.tenure || [],
      showAccordionComponent: false,
      component: (
        <DrawerSingleSelect
          options={tabOptions.tenure}
          value={(localValues?.tenure as string) || ""}
          onSelect={(value) => {
            onChangeLocalValues({ type: "tenure", value });
          }}
        />
      ),
      accordionComponent: (
        <DrawerSingleSelect
          options={tabOptions.tenure}
          placeholder={t("tenure")}
          value={(localValues?.tenure as string) || ""}
          onSelect={(value) => {
            onChangeLocalValues({ type: "tenure", value });
          }}
          variant={EDrawerSingleSelectVariant.MODAL}
        />
      ),
    },
  ];

  const [activeTab, setActiveTab] = useState<string>(totalTabs[0].value);

  const onTabClick = (value: string) => {
    if (value === activeTab) return;
    setActiveTab(value);
  };

  const activeOption = totalTabs.find((t) => t.value === activeTab);
  if (variant === EFilterBarDrawerContentVariant.ACCORDION) {
    return (
      <>
        <div className="flex flex-col">
          <div className="flex flex-col gap-3 border-b border-b-gableGreen5 py-6">
            <Typo
              tag="p"
              variant={ETypoVariant.BODY_TITLE_14}
              color={ETypoColor.TEXT}
              className="text-body-title-14 font-bold"
            >
              {totalTabs?.[0]?.label}
            </Typo>
            <div>{totalTabs?.[0]?.accordionComponent}</div>
          </div>
          {totalTabs
            ?.filter((totalTab) => totalTab?.value !== "location")
            ?.map(
              ({
                value,
                label,
                options = [],
                accordionComponent,
                showAccordionComponent = false,
              }) => {
                const defaultChecked = value === "location";
                return (
                  <div
                    className="border-b border-b-gableGreen5 py-6"
                    key={value}
                  >
                    <Accordion
                      key="location"
                      defaultChecked={false}
                      icon="arrow"
                      className="rounded-none py-0 [&>input]:!min-h-[auto]"
                      onClick={() => {
                        if (!showAccordionComponent) {
                          setOpenSelectModal({
                            open: true,
                            field: value,
                            options,
                            title: label,
                          });
                        }
                      }}
                    >
                      <Accordion.Title className="!min-h-[auto] p-0 text-color after:!top-1/2">
                        <Typo
                          tag="p"
                          variant={ETypoVariant.BODY_TITLE_14}
                          color={ETypoColor.TEXT}
                          className="text-body-title-14 font-bold"
                        >
                          {label}
                        </Typo>
                      </Accordion.Title>
                      <Accordion.Content className="!p-0 !pt-3">
                        {accordionComponent}
                      </Accordion.Content>
                    </Accordion>
                    {!showAccordionComponent && (
                      <Typo
                        tag="p"
                        variant={ETypoVariant.HEADER_14}
                        color={ETypoColor.TEXT}
                        className="font-normal"
                      >
                        {options?.find(
                          (option) => option?.id === localValues?.[value] || ""
                        )?.name || localValues?.[value]}
                      </Typo>
                    )}
                  </div>
                );
              }
            )}
        </div>
        <OptionSelectModal
          title={openSelectModal?.title || ""}
          open={openSelectModal?.open}
          onClose={() =>
            setOpenSelectModal({
              open: false,
              field: "top",
              options: [],
              title: "",
            })
          }
          options={openSelectModal?.options || []}
          value={localValues?.[openSelectModal?.field] || ""}
          onChange={(value) => {
            onChangeLocalValues({ type: openSelectModal?.field, value });
            setOpenSelectModal({
              open: false,
              field: "top",
              options: [],
              title: "",
            });
          }}
        />
      </>
    );
  }

  return (
    <div className="hidden flex-col gap-3 lg:flex">
      <Tabs variant="boxed" className="bg-background">
        {totalTabs?.map(({ value, label }) => {
          const isActive = value === activeTab;
          return (
            <Tabs.Tab
              key={value}
              active={isActive}
              onClick={() => onTabClick(value)}
              className="border-none text-color"
            >
              <Typo
                tag="p"
                variant={
                  isActive ? ETypoVariant.HEADER_16 : ETypoVariant.BODY_TITLE_16
                }
                color={isActive ? ETypoColor.BLACK : ETypoColor.TEXT}
                className={cn(!isActive && "opacity-50")}
              >
                {label}
              </Typo>
            </Tabs.Tab>
          );
        })}
      </Tabs>
      <div className="tabs-content">{activeOption?.component}</div>
    </div>
  );
};

export default FilterBarDrawerContent;
